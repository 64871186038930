<template>
  <div class="waveWrapper waveAnimation">
  <div class="waveWrapperInner bgTop">
    <div class="wave waveTop" style="background-image: url('http://front-end-noobs.com/jecko/img/wave-top.png')"></div>
  </div>
  <div class="waveWrapperInner bgMiddle">
    <div class="wave waveMiddle" style="background-image: url('http://front-end-noobs.com/jecko/img/wave-mid.png')"></div>
  </div>
  <div class="waveWrapperInner bgBottom">
    <div class="wave waveBottom" style="background-image: url('http://front-end-noobs.com/jecko/img/wave-bot.png')"></div>
  </div>
    <div class="register">
      <div class="topper">
      <material-card class="round">
      <v-card-text class="tinggikartu">
        <template v-if="tanya[indexPertanyaan].tipe==='file'">
          <v-file-input placeholder="Unggah file anda"
          prepend-icon="fa fa-file" 
          :label="tanya[indexPertanyaan].label"
          :v-model="itemRegister[tanya[indexPertanyaan].model]"/>
        </template>
        <template v-else>
          <v-text-field 
        v-model="itemRegister[tanya[indexPertanyaan].model]" 
      :label="tanya[indexPertanyaan].label"
      :type="tanya[indexPertanyaan].tipe"
       class="input"/>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-btn v-if="indexPertanyaan>=1" text style="color:orange;">Kembali</v-btn>
          <v-spacer/>
          <v-btn text style="color:orange;" v-on:click="lanjut">Lanjut</v-btn>
        </v-row>
      </v-card-actions>
    </material-card>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      result:null,
      indexPertanyaan:0,
      tanya:[
        {label:'Nama Komunitas',
        tipe:'text',
        model:'namaKomunitas'},
        {
          label:'Logo Komunitas',
          tipe:'file',
          model:'logoKomunitas',
        },
        {
          label:'Nama Hosting',
          tipe: 'text',
          model:'hosting',
        },
        {
          label:'Nomor Hp',
          tipe:'text',
          model:'nomorPonsel',
        }

      ],
      itemRegister:{},
    }
  },
  methods:{
    lanjut(){
      if(this.indexPertanyaan<this.tanya.length){
        this.indexPertanyaan++;
      }else{
        //kirim data
      }
    }
  }
}
</script>

<style scoped>
.tinggikartu{
  height: 200px;
  width: 400px;
  font: 'Roboto';
}
.register{
  background: orange; 
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.round{
  border-radius: 30%;
  -webkit-border-radius:30px;
  -moz-border-radius:30px;
}
.topper{
  z-index: 100;
}
.pertanyaan{
  width: 50%;
  height: 100px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4c281a;
}
.waveWrapper {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
}
.waveWrapperInner {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 100%;
    bottom: -1px;
    background-image: linear-gradient(to top, #fc8621 20%, #7e2c06 80%);
}
/* .waveWrapperInner {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 100%;
    bottom: -1px;
    background-image: linear-gradient(to top, #fc8621 20%, #ff6721 80%);
} */
.bgTop {
    z-index: 15;
    opacity: 0.5;
    background-image: '../../assets/images/wave-top.png';
}
.bgMiddle {
    z-index: 10;
    opacity: 0.75;
    background-image: '../../assets/images/wave-mid.png';
}
.bgBottom {
    z-index: 5;
    background-image: '../../assets/images/wave-bot.png';
}
.wave {
    position: absolute;
    left: 0;
    width: 200%;
    height: 100%;
    background-repeat: repeat no-repeat;
    background-position: 0 bottom;
    transform-origin: center bottom;
}
.waveTop {
    background-size: 50% 100px;
}
.waveAnimation .waveTop {
  animation: move-wave 3s;
   -webkit-animation: move-wave 3s;
   -webkit-animation-delay: 1s;
   animation-delay: 1s;
}
.waveMiddle {
    background-size: 50% 120px;
}
.waveAnimation .waveMiddle {
    animation: move_wave 10s linear infinite;
}
.waveBottom {
    background-size: 50% 100px;
}
.waveAnimation .waveBottom {
    animation: move_wave 15s linear infinite;
}
@keyframes move_wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }
    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}
</style>